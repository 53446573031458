const MemInfo = [
    {
        name: 'King David Olufemi',
        aka: 'King David the second',
        isLeader: ' (Unit Coordinator)',
        inspiration: 'I was invited based on my skills, I started off helping out but wasn\'t committed until I decided to. Today I\'m grateful to God for what I did.',
        subUnit: ['all subunits',],
        skills: ['Graphics Design', 'Web Design', 'Photograpghy', 'Content & Copywriting', 'more...'],
        favMember: 'All publicity unit members',
        moment: "The day I signed up for publicity",
        biz: null,
        shoutOut: 'The entirety of PUBLICITY UNIT MEMBERS, both full and part time. Like I address you almost every morning...You are the best unit in WCFFUTA',
        pic: 'david_olufemi.jpg'
    },
    {
        name: 'Olabosinde Elijah',
        aka: 'Kekeina',
        isLeader: ' (Unit P.R.O)',
        inspiration: 'A passion to serve God',
        subUnit: ['photography', 'videography & YouTube', 'graphics', 'publication & press'],
        skills: ['Fast learner', 'Drumming', 'Photography'],
        favMember: 'King David',
        moment: "The entire service",
        biz: null,
        shoutOut: 'Emmanuel, Joy and all publicity members',
        pic: 'elijah.jpg'
    },
    {
        name: 'Olowo Kamiye',
        aka: 'Kamiye',
        isLeader: ' (Sub-unit Leader)',
        inspiration: 'I was added to publicity unit by our previous vice president and my willingness to help the publicity of God\'s word kept me.',
        subUnit: ['videography', 'social media'],
        skills: ['Public speaking', 'Creative writing', 'Social management', 'Project management', 'Video editing', 'Content creation'],
        favMember: 'Precious',
        moment: "Has to be the IT sendforth for my set and the energy that comes from handover services",
        biz: 'Meteorologist for the time being. Community manager and keynote speaker',
        shoutOut: 'KIng David',
        pic: 'kamiye.jpg'
    },
    {
        name: 'Precious Ojo-numa',
        aka: 'Numa',
        isLeader: ' (Sub-unit Leader)',
        inspiration: 'I was drawn to join the unit after I saw their coverage of the WCF Week 23 drama night. I was initially going to join as a part-time member but this unit is too interesting to be part of it only part-of-the-time',
        subUnit: ['videography', 'social media'],
        skills: ['"Alot"'],
        favMember: null,
        moment: "Has to be the IT sendforth for my set and the energy that comes from handover services",
        biz: 'CEO Pancake Casual Corner, Tailored by UMA, Precious Noah TV on YouTube, upcoming Civil Engineer and Trillionaire',
        shoutOut: 'ALl publicity members',
        pic: 'precious.jpg'
    },
    {
        name: 'Adenuga Joshua',
        aka: 'Big_Josh',
        isLeader: ' (Sub-unit Leader)',
        inspiration: 'To serve God and the interest of His kingdom',
        subUnit: ['photography'],
        skills: ['Great with conversations', 'Writing', 'Reading', 'Cooking'],
        favMember: null,
        moment: "The day I signed up for publicity",
        biz: 'Web design & Development, Digital Marketing, Home and office cleaning',
        shoutOut: 'my mum for giving birth to this awesome spec',
        pic: 'joshua.jpg'
    },
    {
        name: 'Omolaju Emmanuel',
        aka: 'NRG',
        isLeader: ' (Sub-unit Leader)',
        inspiration: 'To serve God and the interest of His kingdom with my skills and talents',
        subUnit: ['graphics', 'publication & press'],
        skills: ['Web development', 'Copywritng', 'Graphics design', 'Public speaking'],
        favMember: 'My coord and assistant coord, as well as Joy and Joyful',
        moment: "WCF Week '23",
        biz: 'Web design & Development',
        shoutOut: 'God, for bringing me to serve Him alongside some of the best people on the planet',
        pic: 'emmanuel.jpg'
    },
    {
        name: 'Adedamola',
        aka: 'Ajax',
        inspiration: 'A passion for service',
        subUnit: ['Photography',],
        skills: ['Photography', 'Video editing'],
        favMember: 'King David',
        moment: "Wosrhip",
        biz: null,
        shoutOut: 'all WCF members',
        pic: 'ajax.jpg'
    },
    {
        name: 'Joseph-Bello Favour',
        aka: 'Jaybee',
        isLeader: ' (Sub-unit Leader)',
        inspiration: 'The passion to serve God and bring people to His kingdom through written words',
        subUnit: ['publication & press'],
        skills: ['Writing', 'Content creation', 'Smiling'],
        favMember: 'King David',
        moment: "Worship sessions and the word",
        biz: null,
        shoutOut: 'Todimu, Sis. Love and all members of publicity',
        pic: 'favour.jpg'
    },
    {
        name: 'Alonge Ifeoluwa',
        aka: 'Ife',
        inspiration: 'Passion and zeal to serve God',
        subUnit: ['Projection', 'Videography & YouTube', 'Graphics'],
        skills: ['cooking', 'diplomacy'],
        favMember: 'Dave and Favour',
        moment: "My first day at the fellowship",
        biz: 'Crypto Trading',
        shoutOut: 'Boluwatife & Mama',
        pic: 'ife.jpg'
    },
    {
        name: 'Olaiye James',
        aka: 'Jaymz',
        inspiration: 'Passion to serve God',
        subUnit: ['videography & YouTube'],
        skills: ['Football'],
        favMember: 'Joyful',
        moment: "Pre-exam service (sharing of groceries)",
        biz: 'Full-time student',
        shoutOut: 'all publicity & media members',
        pic: 'james.jpg'
    },
    {
        name: 'Adebule Joy',
        aka: 'Shizzabelle',
        inspiration: 'The pictures taken and I like the prayers after service',
        subUnit: ['publication & press', 'photography'],
        skills: ['Writing', 'Singing', 'Art', 'Decoration', 'Acting'],
        favMember: 'King David',
        moment: "WCF Week '23",
        biz: null,
        shoutOut: 'My level coordinators, Bolu and Ore and the realm touchers',
        pic: 'joy.jpg'
    },
    {
        name: 'Olawuyi Joyful',
        aka: 'Jayflex',
        isLeader: 'Sub-unit Leader',
        inspiration: 'The love and passion for God',
        subUnit: ['projection', 'videography & YouTube'],
        skills: ['Graphics Design'],
        favMember: 'King David, NRG, Elijah, Ms. Joy',
        moment: "Worship sessions",
        biz: 'Freelancing',
        shoutOut: 'To all publicity members',
        pic: 'joyful.jpg'
    },
    {
        name: 'Olukanni Abiodun',
        aka: 'Day Walker',
        inspiration: 'Passion to serve God and learn from publicity members',
        subUnit: ['projection'],
        skills: ['Website Design'],
        favMember: 'King David',
        moment: "Prayer charge",
        biz: null,
        shoutOut: 'all publicity & Bro. Yinka',
        pic: 'micheal.jpg'
    },
    {
        name: 'Olaoluwa Israel',
        aka: 'Skye',
        inspiration: 'Passion to serve God',
        subUnit: ['photography'],
        skills: ['Cooking', 'Good with conversations'],
        favMember: 'King David',
        moment: "Prayer sessions",
        biz: 'Photography',
        shoutOut: 'all publicity members',
        pic: 'ola.jpg'
    },
    {
        name: 'Elebute Olarenwaju',
        aka: 'Fresh',
        inspiration: 'Videograoghy & Photography',
        subUnit: ['videography & YouTube', 'graphics'],
        skills: ['Web design', 'Public Speaking', 'Jama jama'],
        favMember: 'Mr. Kamiye',
        moment: "Brother's night. Eba day to be precise",
        biz: 'Freelancing',
        shoutOut: 'all WCF members',
        pic: 'olarenwaju.jpg'
    },
    {
        name: 'Odemakinde Testimony',
        aka: 'Merveille',
        isLeader: ' (Unit Treasurer)',
        inspiration: "The zeal to work in God's vineyard",
        subUnit: ['treasurer', 'welfare', 'notice board'],
        skills: ['Content Creation', 'Youtuber'],
        favMember: 'Joshua and King David',
        moment: "WCF '23",
        biz: 'Underwear & Pyjamas Sales',
        shoutOut: 'Oreoluwa an Mercy',
        pic: 'testimony.jpg'
    },
    {
        name: 'Ayodele Temilola',
        aka: 'Tifeh gold',
        inspiration: "To work for God and learn from Publicity unit members",
        subUnit: ['Photograghy', 'Projection'],
        skills: ['Fashion designing'],
        favMember: 'King David',
        moment: "Prayer Charge and Worship",
        biz: null,
        shoutOut: 'Joyful, Olaoluwa and Elijah',
        pic: 'tifeh.jpg'
    },
    {
        name: 'Akinnagbe Timilehin',
        aka: 'Timzy',
        inspiration: "The love and passion for God",
        subUnit: ['Photography'],
        skills: ['Web design', 'Trading'],
        favMember: 'Mr. David and Elijah',
        moment: "Worship, word and prayer sessions",
        biz: 'Networking and Freelance',
        shoutOut: 'All publicity members',
        pic: 'timi.jpg'
    },
    {
        name: 'Ikuku Wisdom',
        aka: 'Wizzywise',
        inspiration: "I wanted to join a unit to serve God and I had knowledge in video editing",
        subUnit: ['Videography'],
        skills: ['videography', 'video editing'],
        favMember: 'Favour',
        moment: "100 level hangout",
        biz: 'Plumbing/Quantity Survey',
        shoutOut: 'Elijah',
        pic: 'wisdom.jpg'
    },
]
export default MemInfo
